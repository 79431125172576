<template>
  <div>
    <div v-bind:class="!$vuetify.breakpoint.mobile ? 'news-wrapper' : ''">
      <v-data-table :headers="headers" :items="articles" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Вести</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Нова вест
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.title"
                          label="Назив вести"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.description"
                          label="Опис вести"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.url"
                          label="Урл вести"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea 
                          style="white-space: pre-wrap;"
                          v-model="editedItem.text"
                          filled
                          label="Текст вести"
                          auto-grow
                        ></v-textarea>
                        <!-- <v-text-field
                          v-model="editedItem.text"
                          label="Текст вести"
                        ></v-text-field> -->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Одустани
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Сачувај
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="fit-content">
              <v-card>
                <v-card-title class="headline"
                  >Да ли сте сигурни да желите да обришете вест?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Одустани</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { db } from "@/main";
export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Vest",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: "",
      editedItem: {
        title: "",
        description: "",
        date: "",
        url: "",
        text: "",
      },
      defaultItem: {
        title: "",
        description: "",
        date: "",
        url: "",
        text: "",
      },
      watch: {
        dialog(val) {
          val || this.close();
        },
        dialogDelete(val) {
          val || this.closeDelete();
        },
      },
      articles: [],
      title: "",
      description: "",
      url: "",
      date: "",
      text: "",
      rules: [
        (value) => !!value || "Обавезно поље.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
    };
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    // get articles
    async getArticles() {
      let snapshot = await db.collection("article").get();
      let arr = [];
      snapshot.forEach((doc) => {
        let appData = doc.data();
        appData.id = doc.id;
        arr.push(appData);
      });
      this.articles = arr;
    },
    // add articles
    async addArticle() {
      if (
        this.editedItem.title &&
        this.editedItem.description &&
        this.editedItem.text
      ) {
        await db.collection("article").add({
          title: this.editedItem.title,
          description: this.editedItem.description,
          date: new Date().getTime(),
          url: this.editedItem.url,
          text: this.editedItem.text,
        });
        // this.getEvents()
        (this.title = ""),
          (this.description = ""),
          (this.date = ""),
          // this.url = '',
          (this.text = "");
      } else {
        alert("You must enter event name, start, and end time");
      }
    },
    async updateArticle(ev) {
      await db.collection("article").doc(this.editedIndex).update({
        title: ev.title,
        description: ev.description,
        date: ev.date,
        url: ev.url,
        text: ev.text,
        // details: ev.details
      });
      this.editedIndex = null;
    },
    async deleteNews(ev) {
      await db.collection("article").doc(ev).delete();
      this.getArticles();
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleteNews(this.editedItem.id);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = "";
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = "";
      });
    },

    save() {
      if (this.editedIndex != "") {
        this.updateArticle(this.editedItem);
      } else {
        this.addArticle();
      }
      this.getArticles();
      this.close();
    },
  },
};
</script>
<style scoped>
.news-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 1rem;
}
.add-card {
  min-width: 500px;
}
.add-card-mobile {
  max-width: 374px;
}

tbody tr td {
  text-align: end !important;
}
</style>